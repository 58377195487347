import {PowderguideApiInterface, RegistrationRequest, RegistrationResponse} from "./powderguide-api";
import {Translator, LanguageLabels} from "./translator";

type RegistrationPhase = 'initial'|'pending_email'|'confirming'|'success'|'resend';

export class PowderguideRegistration {
    public loading: boolean = false;
    public phase: RegistrationPhase = 'initial';
    public error: string|null = null;
    public validationErrors: { [property: string]: string[] } = { };
    private translator: Translator = new Translator({ });

    constructor(
        private powderguideApi: PowderguideApiInterface,
        private confirmationToken: string|null,
    ) { }

    init(languageLabels: LanguageLabels): void {
        this.translator.addLabels(languageLabels);
        if (this.confirmationToken !== null) {
            this.phase = 'confirming';
            this.confirmRegistration();
        }
    }

    async register(userProperties: RegistrationRequest): Promise<void> {
        if (this.loading) {
            return;
        }

        this.error = null;
        this.loading = true;
        this.validationErrors = {};

        let result: RegistrationResponse|null;
        try {
            result = await this.powderguideApi.register(userProperties);
        } catch {
            result = null;
        }

        if (result !== null && result.errors) {
            this.validationErrors = result.errors;
            this.error = this.translator.translate('errors.invalid_fields');
        } else if (result === null || !result.success) {
            this.error = this.translator.translate('errors.registration_failed');
        } else {
            this.phase = 'pending_email';
        }

        this.loading = false;
    }

    async confirmRegistration(): Promise<void> {
        if (this.confirmationToken === null) {
            this.error = this.translator.translate('errors.confirmation_token_missing');
            return;
        }
        this.error = null;
        this.loading = true;
        this.validationErrors = {};
        const result = await this.powderguideApi.confirmRegistration(this.confirmationToken);
        switch (result.result) {
            case 'success':
                this.phase = 'success';
                break;
            case 'error_expired':
                this.error = this.translator.translate('errors.confirmation_token_expired');
                this.phase = 'resend';
                break;
            case 'error_already_confirmed':
                this.error = this.translator.translate('errors.user_already_confirmed');
                this.phase = 'success';
                break;
            case 'error_invalid_token':
            case 'error_token_mismatch':
                this.error = this.translator.translate('errors.confirmation_token_invalid');
                this.phase = 'resend';
                break;
            case 'error_generic':
            default:
                this.error = this.translator.translate('errors.confirmation_failed');
                break;
        }
        this.loading = false;
    }

    async resendRegistrationEmail(emailAddress: string): Promise<void> {
        this.error = null;
        this.loading = true;
        this.validationErrors = {};
        const result = await this.powderguideApi.resendRegistrationEmail(emailAddress);
        if (!result.success) {
            this.error = this.translator.translate('errors.email_failed');
        } else {
            this.phase = 'pending_email';
        }
        this.loading = false;
    }

    transitionIntoResendPhase() {
        this.error = null;
        this.loading = false;
        this.phase = 'resend';
    }
    transitionIntoInitialPhase() {
        this.error = null;
        this.loading = false;
        this.phase = 'initial';
    }
}
