import {Comment, PowderguideApiInterface} from "./powderguide-api";
import {PowderguideAuthentication} from "./powderguide-authentication";
import {LanguageLabels, Translator} from "./translator";

export class PowderguideComments {

    public comments: Comment[] = [];
    public loading: boolean = true;
    public error: string|null = null;
    private translator: Translator = new Translator({ });

    public get canDelete(): boolean {
        return this.authentication.user && this.authentication.user.is_admin;
    }

    constructor(
        private api: PowderguideApiInterface,
        private authentication: PowderguideAuthentication,
        private target: 'entry'|'conditions_report',
        private uuid: string,
    ) {}

    public async init(languageLabels: LanguageLabels): Promise<void> {
        this.translator.addLabels(languageLabels);
        try {
            this.loading = true;
            await this.authentication.init({ });
            this.comments = await this.api.loadComments(this.target, this.uuid);
            this.loading = false;
        } catch (e) {
            this.error = e.message;
        }
    }

    public async post(comment: string): Promise<void> {
        if (comment.trim().length < 3) {
            this.error = this.translator.translate('errors.comment_to_short');
            return;
        }

        this.loading = true;
        const user = this.api.getUser();
        if (!user) {
            this.error = this.translator.translate('errors.not_logged_in');
            this.loading = false;
            return;
        }

        await this.api.postComment(this.target, this.uuid, comment);
        await this.init({ });
    }

    public async delete(uuid: string): Promise<void> {
        this.loading = true;
        if (!this.authentication.user || !this.authentication.user.is_admin) {
            this.error = this.translator.translate('errors.cannot_delete');
        }

        await this.api.deleteComment(uuid);
        await this.init({ });
    }
}
