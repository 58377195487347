export class PowderguideApiTokenStorage {
    public store(token: string) {
        localStorage.setItem('powderguide-api-token', token)
    }

    public get(): string | null {
        return localStorage.getItem('powderguide-api-token')
    }

    public clear() {
        localStorage.removeItem('powderguide-api-token')
    }
}
