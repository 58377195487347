
function mainWidth(): number {
    const element = document.querySelector('main#content .fluid-container');
    const width = element.clientWidth;
    const style = getComputedStyle(element);

    const paddingLeft = parseFloat(style.getPropertyValue('padding-left'));
    const paddingRight = parseFloat(style.getPropertyValue('padding-right'));

    return width - paddingRight - paddingLeft;
}
function updateIframeSize(iframe: HTMLIFrameElement): void {
    if (iframe.hasAttribute('data-iframe-auto-width')) {
        iframe.style.width = mainWidth() + 'px';
    }
    if (iframe.hasAttribute('data-iframe-auto-height')) {
        iframe.style.height = iframe.contentWindow.document.body.scrollHeight + 'px';
    }
}

function updateIframeSizes(): void {
    document.querySelectorAll('iframe').forEach(updateIframeSize);
}

window.addEventListener('resize', updateIframeSizes);
window.addEventListener('load', updateIframeSizes);
window.addEventListener('DOMContentLoaded', updateIframeSizes);
updateIframeSizes();
