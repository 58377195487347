import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';


const lightbox = new PhotoSwipeLightbox({
  // may select multiple "galleries"
  gallery: '.c-gallery',

  // Elements within gallery (slides)
  children: '.c-gallery-item',

  paddingFn: (viewportSize, itemData, index) => {
    return {
      // check based on viewport size
      top: 80,
      right: viewportSize.x < 980 ? 0 : 80,
      bottom: 80,
      left: viewportSize.x < 980 ? 0 : 80,

    };
  },

  // setup PhotoSwipe Core dynamic import
  pswpModule: () => import('photoswipe')

});


// Parse data-pswp-webp-src attribute
lightbox.addFilter('itemData', (itemData, index) => {
    const webpSrc = itemData.element.dataset.pswpWebpSrc;
    if (webpSrc) {
      itemData.webpSrc = webpSrc;
    }
    return itemData;
  });

  // use <picture> instead of <img>
  lightbox.on('contentLoad', (e) => {
      const { content, isLazy } = e;

      if (content.data.webpSrc) {
        // prevent to stop the default behavior
        e.preventDefault();

        content.pictureElement = document.createElement('picture');

        const sourceWebp = document.createElement('source');
        sourceWebp.srcset = content.data.webpSrc;
        sourceWebp.type = 'image/webp';

        const sourceJpg = document.createElement('source');
        sourceJpg.srcset = content.data.src;
        sourceJpg.type = 'image/jpeg';

        content.element = document.createElement('img');
        content.element.src = content.data.src;
        content.element.setAttribute('alt', '');
        content.element.className = 'pswp__img';

        content.pictureElement.appendChild(sourceWebp);
        content.pictureElement.appendChild(sourceJpg);
        content.pictureElement.appendChild(content.element);

        content.state = 'loading';

        if (content.element.complete) {
          content.onLoaded();
        } else {
          content.element.onload = () => {
            content.onLoaded();
          };

          content.element.onerror = () => {
            content.onError();
          };
        }
      }
  });


  // by default PhotoSwipe appends <img>,
  // but we want to append <picture>
  lightbox.on('contentAppend', (e) => {
    const { content } = e;
    if (content.pictureElement && !content.pictureElement.parentNode) {
      e.preventDefault();
      content.slide.container.appendChild(content.pictureElement);
    }
  });

  // for next/prev navigation with <picture>
  // by default PhotoSwipe removes <img>,
  // but we want to remove <picture>
  lightbox.on('contentRemove', (e) => {
    const { content } = e;
    if (content.pictureElement && content.pictureElement.parentNode) {
      e.preventDefault();
      content.pictureElement.remove();
    }
  });

  lightbox.on('uiRegister', function() {
    lightbox.pswp.ui.registerElement({
      name: 'custom-caption',
      order: 9,
      isButton: false,
      appendTo: 'root',
      html: 'Caption text',
      onInit: (el, pswp) => {
        lightbox.pswp.on('change', () => {
          const currSlideElement = lightbox.pswp.currSlide.data.element;

          let captionHTML = '';
          if (currSlideElement) {
            const hiddenCaption = currSlideElement.querySelector('.caption-content');
            if (hiddenCaption) {
              // get caption from element with class hidden-caption-content
              captionHTML = hiddenCaption.innerHTML;
            }
          }
          el.innerHTML = captionHTML || '';
        });
      }
    });
  });

  lightbox.init();
