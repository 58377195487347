
export type LanguageLabels = {
    [key: string]: string|LanguageLabels,
}

export class Translator {

    constructor(
        private languageLabels: LanguageLabels,
    ) { }

    public addLabels(languageLabels: LanguageLabels): void {
        this.languageLabels = {
            ...this.languageLabels,
            ...languageLabels,
        };
    }

    public translate(key: string, replacements: {[key: string]: string} = {}): string {
        let translation = this.get(key);
        if (translation === null) {
            console.warn(`Translation for key "${key}" not found`);
            return key;
        }

        for (const [key, value] of Object.entries(replacements)) {
            translation = translation.replace(`:${key}`, value);
        }

        return translation
    }

    private get(key: string): string|null {
        const path = key.split('.');
        const leaf = path.pop();

        let current = this.languageLabels;
        for (const part of path) {
            if (!current[part]) {
                return null;
            }
            current = current[part] as LanguageLabels;
        }

        return `${current[leaf]}`;
    }

}
