import type { LinkifyIt } from "linkify-it";

export class Linkify {

    // Use getLinkifyIt
    private _linkifyIt: LinkifyIt|null = null;

    public async parseFromDomNode(element: HTMLElement): Promise<void> {
        const linkified = await this.parse(element.innerText)
        if (linkified === element.innerText) {
            return;
        }

        element.innerHTML = linkified;
    }

    public async parse(stringContent: string): Promise<string> {
        const linkifyIt = await this.getLinkifyIt();
        if (!linkifyIt.test(stringContent)) {
            return stringContent;
        }

        const matches = linkifyIt.match(stringContent);

        // In order for the operations to not mess up the index of other matches, we start
        // at the back working towards the front.
        const matchesLastToFirst = matches.sort((a, b) => b.index - a.index)
        for (const match of matchesLastToFirst) {
            const url = new URL(match.url)
            const linkAttributes = {
                href: match.url,
                target: (window.location.hostname === url.hostname) ? '_self' : '_blank',
            }

            stringContent = stringContent.substring(0, match.index)
                + this.createHtml('a', linkAttributes, match.url)
                + stringContent.substring(match.lastIndex)
        }

        return stringContent;
    }

    private createHtml(tagName: string, attributes: Record<string, string>, content: string): string {
        let attributeString = '';
        for (const [ key, value ] of Object.entries(attributes)) {
            attributeString += ` ${key}="${value}"`;
        }

        return `<${tagName} ${attributeString}>${content}</${tagName}>`;
    }

    private async getLinkifyIt(): Promise<LinkifyIt> {
        if (this._linkifyIt) {
            return this._linkifyIt;
        }

        const { default: LinkifyIt } = await import('linkify-it')
        this._linkifyIt = new LinkifyIt();
        this._linkifyIt.tlds([ 'de', 'com', 'net', 'ch', 'org', 'info', 'fr', 'it' ]);

        return this._linkifyIt;
    }
}
