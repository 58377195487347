import {PowderguideApiInterface} from "./powderguide-api";
import {LanguageLabels, Translator} from "./translator";

type PasswordResetPhase = 'initial'|'pending_email'|'reset_form'|'success';

export class PowderguidePasswordReset {
    public loading: boolean = false;
    public phase: PasswordResetPhase = 'initial';
    public error: string|null = null;
    public showBackLink = false;
    public validationErrors: {[key: string]: string[]} = {};
    private translator: Translator = new Translator({ });

    constructor(
        private powderguideApi: PowderguideApiInterface,
        private confirmationToken: string|null,
    ) {}

    init(languageLabels: LanguageLabels): void {
        this.translator.addLabels(languageLabels);
        if (this.confirmationToken !== null) {
            this.phase = 'reset_form';
        }
    }

    public async sendResetEmail(email: string): Promise<void> {
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.error = null;
        this.validationErrors = {};
        this.showBackLink = false;

        const result = await this.powderguideApi.sendPasswordResetMail(email);
        if (result.errors) {
            this.error = this.translator.translate('errors.invalid_fields');
            this.validationErrors = result.errors;
        } else if (result.success) {
            this.phase = 'pending_email';
        } else {
            this.error = this.translator.translate('errors.generic');
        }

        this.loading = false;
    }

    public async resetPassword(password: string, passwordConfirmation: string): Promise<void> {
        if (this.loading) {
            return;
        }
        if (this.confirmationToken === null) {
            this.error = this.translator.translate('errors.token_missing');
            return;
        }
        if (password !== passwordConfirmation) {
            this.error = this.translator.translate('errors.password_mismatch');
            return;
        }

        this.loading = true;
        this.error = null;
        this.validationErrors = {};
        this.showBackLink = false;

        const result = await this.powderguideApi.resetPassword(
            this.confirmationToken,
            password,
            passwordConfirmation
        );

        if (result.errors) {
            this.error = this.translator.translate('errors.invalid_fields');
            this.validationErrors = result.errors;
        } else {
            switch (result.status) {
                case 'success':
                    this.phase = 'success';
                    break;
                case 'invalid_token':
                    this.error = this.translator.translate('errors.token_invalid');
                    this.showBackLink = true;
                    break;
                case 'token_expired':
                    this.error = this.translator.translate('errors.token_expired');
                    this.showBackLink = true;
                    break;
                case 'already_used':
                    this.error = this.translator.translate('errors.token_already_used');
                    this.showBackLink = true;
                    break;
                default:
                case 'error':
                    this.error = this.translator.translate('errors.generic');
            }
        }

        this.loading = false;
    }
}
