import {PowderguideApiInterface, UserData} from "./powderguide-api";
import {LanguageLabels, Translator} from "./translator";

export class PowderguideAuthentication {
    private _user: UserData|null = null;
    private _error: string|null = null;
    private _loading: Promise<void>|null = null;
    private _initialized: boolean = false;
    private translator: Translator = new Translator({ })

    constructor(
        private powderguideApi: PowderguideApiInterface,
    ) { }

    public get user(): UserData|null {
        return this._user;
    }

    public get error(): string|null {
        return this._error;
    }

    public get loading(): boolean {
        return this._loading !== null;
    }

    public async init(languageLabels: LanguageLabels): Promise<void> {
        this.translator.addLabels(languageLabels);
        if (this._initialized) {
            return;
        }
        if (this._loading !== null) {
            return this._loading;
        }

        this._loading = new Promise(async resolve => {
            this._user = await this.powderguideApi.getUser();
            this._loading = null;
            this._initialized = true;
            resolve();
        })

        return this._loading;
    }

    async login(username: string, password: string): Promise<void> {
        this._loading = new Promise(async () => {
            try {
                this._user = await this.powderguideApi.login(username, password);
            } catch (e) {
                console.error(e)
                this._error = this.translator.translate('errors.generic');
            }
            if (this._user === null) {
                this._error = this.translator.translate('errors.invalid_credentials');
            } else {
                this._error = null;
                window.location.reload();
            }

            this._loading = null;
        })
    }

    async logout(): Promise<void> {
        this._user = null;
        await this.powderguideApi.logout();
        window.location.reload();
    }
}
