if (document.querySelector('.splide') !== null) {
    // import('@splidejs/splide-extension-url-hash').then(({ default: URLHash}) => {});
    import('@splidejs/splide').then(({default: Splide}) => {
        var sliders = document.getElementsByClassName( 'splide' );
        for ( var i = 0; i < sliders.length; i++ ) {
            new Splide( sliders[ i ], {
                autoplay: true,
                type: 'loop',
                perPage: 1,
                gap: '1rem',
                autoWidth: true,
                arrows: false,
                autoHeight: true,
                pagination: true,
                lazyLoad: 'nearby',
            }).mount();
          }
    });
    // Default theme
    import('@splidejs/splide/css');
}
